<template>
  <div class="br11" ref="vlbox">
    <div class="index_main" ref="index_main">
      <div class="index_right">
        <p class="search">
          <el-select
            v-model="searchForm.state"
            v-if="permissoin.includes('query')"
            size="mini" clearable>
            <el-option
              v-for="item in option1"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-input
            v-if="permissoin.includes('query')"
            type="text" clearable
            size="mini"
            v-model="searchForm.name"
            placeholder=" 请输入关键字"
          ></el-input>
          <button
            class="btn1"
            v-if="permissoin.includes('query')"
            @click="
              currentPage2 = 1;
              getData();
            ">
            <i class="el-icon-search"></i>
            查询
          </button>
          <button
            class="btn2"
            v-if="permissoin.includes('add')"
            @click="modal = 1;modalState=0">
            <i class="el-icon-plus"></i>
            新增
          </button>
        </p>
        <div class="table srcollstyle">
          <el-table :data="tableData" style="width: 98%" border>
            <el-table-column type="index" label="排序" width="80">
              <template slot-scope="scope">
                       {{(currentPage2 - 1) * 10 + scope.$index + 1}}
                    </template>
            </el-table-column>
            <el-table-column prop="explain" label="报销名目" width="280" :show-overflow-tooltip="true">
            </el-table-column>
            <!-- <el-table-column prop="quantity" label="材料数量">
            </el-table-column> -->
            <el-table-column prop="type" label="报销类型">
              <template slot-scope="scope">
                <span v-if="scope.row.type == 1">采购报销</span>
                <span v-if="scope.row.type == 2">其他报销</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="amount"
              label="报销金额">
            <template slot-scope="scope">
                  <span class="colorred">￥{{fmoney(scope.row.amount,2)}}</span>
                    </template>
            </el-table-column>
            <el-table-column prop="userName" label="申请人"> </el-table-column>
            <el-table-column prop="createTime" label="申请日期">
            </el-table-column>
            <el-table-column label="报销状态">
              <template slot-scope="scope">
                <span v-if="scope.row.state == 1" class="colorgreen"
                  >已完成</span
                >
                <span v-if="scope.row.state == 2" class="colorblue"
                  >待付款</span
                >
                <span v-if="scope.row.state == 3" class="colororange"
                  >待审核</span
                >
                <span v-if="scope.row.state == 4" class="colorred">驳回</span>
                <!-- <span v-if="scope.row.state == 5" class="colorgreen"
                  >已结清</span
                >
                <span v-if="scope.row.balanceState == 2">（有尾款）</span> -->
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-dropdown trigger="click" @command="handleCommand">
                  <el-button type="primary" plain size="small">
                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      icon="el-icon-search"
                      v-if="permissoin.includes('query')"
                      :command="{ num: 10, data: scope.row }"
                      >查看</el-dropdown-item
                    >
                    <el-dropdown-item
                      icon="el-icon-edit-outline"
                      v-show="scope.row.state == 3&& permissoin.includes('examine')"
                      :command="{ num: 3, data: scope.row }">审核</el-dropdown-item>
                    <el-dropdown-item
                      icon="el-icon-money"
                      v-show="scope.row.state == 2 && permissoin.includes('pay')"
                      :command="{ num: 2, data: scope.row }"
                      >支付</el-dropdown-item>
                    <!-- <el-dropdown-item
                    icon="el-icon-delete" 
                      :command="{ num: 3, data: scope.row }"
                      >删除</el-dropdown-item> -->
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage2"
            prev-text="上一页"
            next-text="下一页"
            :page-size="pageSize"
            layout="total,sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="modal" v-show="modal == 1">
      <div class="inner">
        <p class="title">
          <span v-if='modalState==0'>新增报销</span>
          <span v-else>报销详情</span>
          <span
            @click="
              modal = 0;
              form = {planType:'1',explain:'',invoiceType:'1',payInfo:{},sms:'0' };
              procureDetailForm = {};netArrUpdate=[{}];zfForm={};fileList=[];
          fileList1=[];
          fileList3=[]
              paymentRatio = 1;"
            class="pointer"
          >
            <i class="el-icon-close"></i>
          </span>
        </p>
        <div class="modal_center scrollbar_width0">
          <div>
                <p>
            <span class="spaa"><span class="star">* </span>报销类型</span>
            <el-select v-model="form.type" size="mini" placeholder="请选择">
              <el-option
                v-for="item in option2000"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </p>
           <p  v-show="form.type==1">
              <span class="spa">采购类型</span>
              <el-radio-group v-model="form.planType" size="mini">
                <el-radio-button label="1">计划型采购</el-radio-button>
                <el-radio-button label="2">非计划型采购</el-radio-button>
              </el-radio-group>
            </p>
          <!-- <p v-show="form.type==1&&modalState==0&&form.planType=='1'">
              <span class="spaa"><span class="star">* </span>供应商</span>
              <el-select
                :disabled="modalState != 0"
                clearable
                filterable
                v-model="form.projectId"
                size="mini"
                @change="supplierChange"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in option3"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </p> -->
          <p v-show="form.type==1&&modalState==0&&form.planType==1">
            <span class="spaa"><span class="star">* </span>采购计划</span>
            <el-select
              v-model="form.planId"
              clearable filterable
              @change="procureDetailFormChange"
              size="mini"
              placeholder="请选择"
            >
              <el-option
                  v-for="item in option4"
                  :key="item.id"
                  :label="item.productName"
                  :value="item.id"
                >
                     <span style="margin-right: 12px; font-size: 12px"
                      >{{ item.userName }} · {{ item.createTime }}</span
                    >
                    <span
                      style="margin-right: 12px; color: #333; font-size: 12px"
                      >合同金额:{{ item.total }}</span
                    ><span style="margin-right: 12px; font-size: 12px"
                      >材料:{{ item.productName }}</span
                    >
              </el-option>
            </el-select>
          </p>

          <p v-show="form.type==1&&modalState!=0&&form.planType==1">
              <span class="spa">采购计划</span>
              <span>{{ form.planUser }} · {{ form.planTime }}· {{ form.planMerchant }}</span>
            </p>

             <p v-show="form.type==1&&modalState!=0&&form.planType==2">
              <span class="spa">采购计划</span>
              <span>{{ form.name }}</span>
            </p>

                     <el-collapse
              v-model="activeNames"
              v-show="form.type==1&&form.planType==1"
              accordion
            >
              <el-collapse-item title="计划明细" name="1">
                <el-divider content-position="left">采购明细</el-divider>
                <div class="jstitle">
                  <span style="color: #000">名称</span>
                  <span style="color: #000">数量</span>
                  <span style="color: #000">单价</span>
                  <span style="color: #000">小计</span>
                </div>
                <div
                  class="jstitle"
                  v-for="item in procureDetailForm.procureDetail"
                  :key="item.name"
                >
                  <span>{{ item.name }}</span>
                  <span>{{ item.quantity }}</span>
                  <span>{{ item.unit }}</span>
                  <span>{{ item.unit * item.quantity }}</span>
                </div>
                <p>
                  <span class="spa">总计</span
                  ><span>{{
                    procureDetailForm.total||0 - procureDetailForm.freight||0
                  }}</span>
                </p>
                <p>
                  <span class="spa">运费</span
                  ><span>{{ procureDetailForm.freight }}</span>
                </p>
                <el-divider content-position="left">其他</el-divider>
                <p>
                  <span class="spa">结算</span
                  ><span v-if="procureDetailForm.settlement == '1'"
                    >先款后货</span
                  >
                  <span v-else-if="procureDetailForm.settlement == '2'"
                    >先货后款</span
                  >
                </p>
                <p>
                  <span class="spa">发票</span
                  ><span v-if="procureDetailForm.bill == '1'">无发票</span>
                  <span v-else-if="procureDetailForm.bill == '2'"
                    >普通发票</span
                  >
                  <span v-else-if="procureDetailForm.bill == '3'"
                    >增值税专用发票</span
                  >
                  <span v-if="procureDetailForm.bill != '1'">
                    · {{ procureDetailForm.taxRate }}%</span
                  >
                </p>
                <p>
                  <span class="spa">摘要</span
                  ><span>{{ procureDetailForm.summary }}</span>
                </p>
              </el-collapse-item>
            </el-collapse>


          <!-- <p v-if="form.settlement== 1">
            <span class="spa">支付方式</span>
            <el-select v-model="form.payType" size="mini" placeholder="请选择">
              <el-option
                v-for="item in option41"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </p>
          <p v-show="form.settlement == 1">
            <span class="spa">支付账号</span>
            <el-input
              v-model="form.bankAccount"
              size="mini"
              placeholder="请输入"
            ></el-input>
          </p> -->
           <p v-show="modalState!=0">
            <span class="spaa"><span class="star">* </span>申请人</span>
            <el-input v-model="form.userName" disabled size="mini"></el-input>
          </p>
           <p v-show="modalState!=0">
            <span class="spaa"><span class="star">* </span>申请时间</span>
            <el-input v-model="form.createTime" disabled size="mini"></el-input>
          </p>
          <div v-show="form.type==2">
                        <div class="text_item">
              <span>*</span>报销明细
              <span @click="addFormUpdateItem" v-show="modalState==0">
                <i class="el-icon-plus"></i>
                增加名目</span
              >
            </div>
            <div class="jstitle">
              <span>名目</span>
              <span>摘要</span>
              <span>金额</span>
              <span>关联项目</span>
            </div>
            <div v-show="modalState==0">           
            <div
              class="net"
              v-for="(item, index) in netArrUpdate"
              :key="index + 100">
              <el-select
                clearable
                filterable
                v-model="item.name"
                size="mini"
                placeholder="请选择">
                <el-option
                  v-for="item1 in option2"
                  :key="item1.id"
                  :label="item1.name"
                  :value="item1.name">
                </el-option>
              </el-select>

              <el-input
              type="textarea"
               clearable
                v-model="item.summary"
                size="mini"
              ></el-input>

              <el-input
                v-model="item.amount"
                placeholder="请输入"
                size="mini"
              ></el-input>

              <el-select
                clearable
                filterable
                v-model="item.orgId"
                size="mini"
                placeholder="请选择">
                <el-option
                  v-for="item in option50"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <div style="display: flex;margin-top:8px">
                <p class="line"></p>
                <span
                  style="
                    cursor: pointer;
                    position: relative;
                    top: -6px;
                    color: #d62829;
                  "
                  @click="deletenvritem(index)"
                  v-show="index > 0&&modalState==0"
                  ><i class="el-icon-close">删除</i></span>
              </div>
            </div>
            </div>

            <!-- <div v-show="modalState!=0">
              <div class="jstitle" v-for="(item,index) in rmbDetail" :key='index+1000' style="color:#606266">
              <span>{{item.name}}</span>
              <span>{{item.summary}}</span>
              <span>{{item.amount}}</span>
              <span v-if="item.relevant==1">
                
                <el-tooltip  placement="bottom" effect="light">
                  <div slot="content">多行信息<br/>第二行信息</div>
                  <i class="el-icon-paperclip" style='color:#0090ff;cursor: pointer;'></i>
                </el-tooltip>
                </span>
              <span v-if="item.relevant==2"><i class="el-icon-paperclip"></i></span>
            </div>
            </div> -->
             <el-table :data="rmbDetail"  :show-header='false' v-show="modalState!=0" width='570'>
            <el-table-column prop="name"  width="100" :show-overflow-tooltip="true">
            </el-table-column>
             <el-table-column prop="summary"  width="310" :show-overflow-tooltip="true">
            </el-table-column>
             <el-table-column prop="amount"  width="60" :show-overflow-tooltip="true">
            </el-table-column>
             <el-table-column prop="amount"  width="100">
               <template slot-scope="scope">
                 <span v-if="scope.row.relevant==1">
                
                <el-tooltip  placement="bottom" effect="light">
                  <div slot="content">项目名称：{{scope.row.orgName}}<br/>项目地址：{{scope.row.address}}<br/>
                  施工单位：{{scope.row.construction}}</div>
                  <i class="el-icon-paperclip" style='color:#0090ff;cursor: pointer;font-size:16px'></i>
                </el-tooltip>
                </span>
              <span v-if="scope.row.relevant==2"><i class="el-icon-paperclip" style='font-size:16px'></i></span>
                 </template>
            </el-table-column>
            </el-table>
          </div>
          </div>
          <div>
          <p class="disabledred" >
            <span class="spaa"><span class="star">* </span>报销金额</span>
            <el-input v-model="form.amount" :disabled="modalState!=0" size="mini"></el-input>
          </p>
          <p style="display: flex">
            <span class="spa">费用说明</span>
            <el-input v-model="form.explain" type="textarea" size="mini"></el-input>
          </p>
          <!-- <p >
            <span class="spaa"><span class="star">* </span>报销方式</span>
            <el-select v-model="form.rmbType" :disabled="modalState!=0" size="mini" placeholder="请选择">
              <el-option
                v-for="item in option1001"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </p> -->
           <p v-show="modalState == 0">
              <span class="spa">短信提示</span>
              <el-radio-group v-model="form.sms" size="mini">
                <el-radio-button label="1">发送短信</el-radio-button>
                <el-radio-button label="0">不发送</el-radio-button>
              </el-radio-group>
            </p>
          <!-- <p v-show="form.rmbType==4">
            <span class="spaa"><span class="star">* </span>姓名</span>
            <el-input v-model="form.accountName" :disabled="modalState!=0" size="mini"></el-input>
          </p>
                <p class="disabledred" v-show="form.rmbType==4">
            <span class="spaa"><span class="star">* </span>开户行</span>
            <el-input  v-model="form.openBank" :disabled="modalState!=0" size="mini"></el-input>
          </p>
          <p v-show="(form.rmbType==4||form.rmbType==2||form.rmbType==3)">
            <span class="spaa"><span class="star">* </span>账号</span>
            <el-input v-model="form.account" :disabled="modalState!=0" size="mini"></el-input>
          </p> -->
           <p style="display: flex" v-show="modalState==0">
            <span class="spa">报销申请单</span>
            <el-upload
              class="upload-demo"
              :action="axiosUrl + '/admin/l/file/uploadPicture'"
              :on-success="handlePreview"
              :on-remove="handleRemove"
              :file-list="fileList"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </p>

           <div v-show="modalState!=0" style="display:flex">
                    <span class="spa" style="min-width:75px">报销申请单</span>
                    <div>
                      <el-image
                      v-for="url in form.accountStatementArr"
                      :key="url"
                      style="width: 100px; height: 80px"
                      fit="contain"
                      :src="url"
                      :preview-src-list="form.accountStatementArr"
                    >
                    </el-image>
                    </div>
                    
                  </div>
            <p  v-show="modalState==0">
              <span class="spa">发票类型</span>
              <el-radio-group v-model="form.invoiceType" size="mini">
                <el-radio label="1">上传发票</el-radio>
                <el-radio label="2">无票报销</el-radio>
              </el-radio-group>
            </p>
          <p style="display: flex" v-show="modalState==0&&form.invoiceType=='1'">
            <span class="spa">发票</span>
            
            <el-upload
              class="upload-demo"
              :action="axiosUrl + '/admin/l/file/uploadPicture'"
              :on-success="handlePreview1"
              :on-remove="handleRemove1"
              :file-list="fileList1"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </p>

          <div v-show="modalState!=0" style="display: flex;">
                    <div  style="min-width:75px">发票及附件</div><div>
                       <el-image
                      v-for="url in form.invoiceAnnexArr"
                      :key="url"
                      style="width: 100px; height: 80px"
                      fit="contain"
                      :src="url"
                      :preview-src-list="form.invoiceAnnexArr">
                    </el-image>
                    </div>
                   </div>

      <p v-show="form.state == 3 || form.state == 4">
        <span class="spa">审批意见</span>
        <el-input type="text" size="mini" v-model="form.describe"></el-input>
      </p>

           <div
              class="item_describe"
              v-show="form.state == 2 && modalState == 2"
            >
              <div class="describe_right">
                <p>
                  <span class="spaa"><span class="star">* </span>支付方式</span>
                  <el-select
                    v-model="zfForm.type"
                    @change="zhChange"
                    size="mini"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in option44"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </p>
                <p>
                  <!-- <span class="spa">银行帐户</span> -->
                  <span class="spaa"><span class="star">* </span>银行账户</span>
                  <el-select
                    v-model="zfForm.bankAccountId"
                    size="mini"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in option33"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                      <span
                        style="margin-right: 12px; color: #333; font-size: 12px"
                        >{{ item.name }}</span
                      >
                      <span
                        style="margin-right: 12px; color: #333; font-size: 12px"
                        >({{ item.account.substr(0,4) }}****{{ item.account.substr(-4) }})</span
                      >
                    </el-option>
                  </el-select>
                </p>
                <p>
                  <!-- <span class="spa">支付金额</span> -->
                  <span class="spaa"><span class="star">* </span>支付金额</span>

                  <el-input v-model="zfForm.amount" size="mini"></el-input>
                </p>
                <p style="display: flex">
                  <span class="spa">支付凭证</span>
                  <el-upload
                    class="upload-demo"
                    :action="axiosUrl + '/admin/l/file/uploadPicture'"
                    :on-success="handlePreview2"
                    :on-remove="handleRemove2"
                    :file-list="fileList3"
                  >
                    <el-button size="small" type="primary">点击上传</el-button>
                  </el-upload>
                </p>
              </div>
            </div>

                  <el-divider v-if="form.payInfo.type" content-position="left"
              >报销支付</el-divider
            >
            <div class="item_describe" v-if="form.payInfo.type">
              <div class="describe_right">
                <div class="spa_blue">
                  <!-- <div style='margin-bottom:10px'><i style="font-size:14px" class="el-icon-paperclip" @click="downloadFile(item)"></i>附件{{index+1}}.jpg</div>   -->
                  <p>
                    <span class="spa">支付方式</span
                    ><span>
                      <span v-if="form.payInfo.type == '1'">现金</span>
                      <span v-else-if="form.payInfo.type == '2'">电子汇款</span>
                      <span v-else-if="form.payInfo.type == '3'">对公账户</span>
                    </span>
                  </p>
                  <p>
                    <span class="spa">帐户名称</span
                    ><span>{{ form.payInfo.name }}</span>
                  </p>
                  <p>
                    <span class="spa">银行账户</span
                    ><span>
                      {{ form.payInfo.account.substr(0,4) }}****{{ form.payInfo.account.substr(-4) }}
                    </span>
                  </p>
                  <p>
                    <span class="spa">支付金额</span
                    ><span>{{ form.payInfo.amount }}</span>
                  </p>
                  <p>
                    <span class="spa">支付时间</span
                    ><span>{{ form.payInfo.sdatatime }}</span>
                  </p>
                  <p>
                    <span class="spa" style="min-width:75px">支付凭证</span>
                    <el-image
                      v-for="url in form.payInfo.formFile"
                      :key="url"
                      style="width: 100px; height: 80px"
                      fit="contain"
                      :src="url"
                      :preview-src-list="form.payInfo.formFile"
                    >
                    </el-image>
                  </p>
                </div>
              </div>
            </div>
          </div>
        
        </div>
        <div class="bottom" v-show="modalState==0">
          <button class="btn_red" @click="onsubmit">保存</button>
          <button
            class="btn_gray"
            @click="
              modal = 0;
              form = {planType:'1',explain:'',invoiceType:'1',payInfo:{} };
              procureDetailForm = {};
              paymentRatio = 1;">取消
          </button>
        </div>
        <div class="bottom" v-show="modalState==2">
          <button class="btn_red" @click="payAddF">支付</button>
          <button
            class="btn_gray"
            @click="
              modal = 0;
              form = {planType:'1',explain:'',invoiceType:'1',payInfo:{} };
              procureDetailForm = {};
              paymentRatio = 1;">取消
          </button>
        </div>
        <div class="bottom" v-show="modalState==3">
          <button class="btn_red" @click="auditInfo('1')">通过</button>
          <button
            class="btn_gray"
            @click="auditInfo('2')">驳回
          </button>
        </div>

        
      </div>
    </div>
  </div>
</template>

<script>
import {
  axiosUrl,
  rmbGetList,
  rmbInfo,
  wxGetOrgList,
  rmbExamine,
  rmbAdd,
  dtGetListByType,
  getListByPay,
  productGetList,
  getListByProcure,
  payAdd,
  merchantGetList
} from "@/api/apis.js";
export default {
  data() {
    return {
      options: [],
      axiosUrl,
      csdata: {},
      form: {
        planType: "1",
        explain: "",
        invoiceType: "1",
        payInfo: {},
        sms: "0"
      },
      info: 1,
      currentPage2: 1,
      treeData: [],
      option44: [
        {
          value: 1,
          label: "现金支付"
        },
        {
          value: 2,
          label: "电子汇款"
        },
        {
          value: 3,
          label: "对公账户"
        }
      ],
      option2000: [
        {
          value: 1,
          label: "采购报销"
        },
        {
          value: 2,
          label: "其他报销"
        }
      ],
      hide: 1,
      modal: 0,
      netArrUpdate: [{}],
      treeList: [],
      fileList: [],
      fileList1: [],
      fileList2: [],
      fileList3: [],
      procureDetailForm: {},
      total: 0,
      searchForm: {},
      permissoin: [],
      pageSize: 10,
      //供应商
      option3: [],
      //产品
      option5: [],
      option50: [],
      option41: [
        { name: "现金", id: 1 },
        { name: "电子汇款", id: 2 },
        { name: "对公账户", id: 3 }
      ],
      paymentRatio: 1,
      modalState: 0,
      payment: 0,
      ruleForm: {
        pwd: 123456
      },
      props: {
        label: "name",
        value: "id",
        children: "children",
        checkStrictly: true
      },
      zfForm: {},
      option33: [],
      option40: [
        { name: "1%", id: 1 },
        { name: "3%", id: 3 },
        { name: "6%", id: 6 },
        { name: "9%", id: 9 },
        { name: "13%", id: 13 }
      ],
      option1001: [
        {
          value: 1,
          label: "现金"
        },
        {
          value: 2,
          label: "微信"
        },
        {
          value: 3,
          label: "支付宝"
        },
        {
          value: 4,
          label: "银行卡"
        }
      ],
      option1: [
        {
          value: "0",
          label: "全部"
        },
        {
          value: "1",
          label: "已完成"
        },
        {
          value: "2",
          label: "待付款"
        },
        {
          value: "3",
          label: "待审核"
        },
        {
          value: "4",
          label: "驳回"
        }
      ],
      option2: [],
      rmbDetail:[],
      activeNames: ["1"],
      option4: [],
      tableData: [],
      rules: {
        orgId: [{ required: true, message: "选择机构", trigger: "blur" }]
      }
    };
  },

  methods: {
    getData() {
      let data = {
        state: this.searchForm.state,
        name: this.searchForm.name,
        pageNum: this.currentPage2,
        pageSize: this.pageSize
      };
      rmbGetList(data).then(res => {
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.total = res.data.total - 0;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      });
    },
    close() {
      this.modal = 0;
    },
    supplierChange() {
      getListByProcure({ merchantId: this.form.projectId }).then(res => {
        if (res.code == 200) {
          this.option4 = res.data;
          // this.option4.forEach((item) => {
          //   if (item.id == v) {
          //     this.procureDetailForm = item;
          //   }
          // });
        } else {
          this.option4 = [];
        }
      });
    },
    //支付
    payAddF() {
      let data = {
        type: this.zfForm.type,
        bankAccountId: this.zfForm.bankAccountId,
        amount: this.zfForm.amount,
        file: this.zfForm.file,
        mainId: this.form.id,
        mainType: 6,
        explain: this.form.explain
      };
      payAdd(data).then(res => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "操作成功"
          });
          this.getData();
          this.modal = 0;
          this.procureDetailForm = {};
          this.form = {
            planType: "1",
            explain: "",
            invoiceType: "1",
            payInfo: {}
          };
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //进行审批
    auditInfo(num) {
      let data = {
        state: num,
        describe: this.form.describe,
        id: this.form.id
      };
      rmbExamine(data).then(res => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "操作成功"
          });
          this.getData();
          this.modal = 0;
          this.procureDetailForm = {};
          this.form = {
            planType: "1",
            explain: "",
            invoiceType: "1",
            payInfo: {}
          };
        } else {
          this.$message.error("操作失败");
        }
        this.close();
      });
    },
    procureDetailFormChange(v) {
      this.option4.forEach(item => {
        if (item.id == v) {
          this.procureDetailForm = item;
          this.form.explain = item.productName;
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage2 = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    //上传文件
    handleRemove(file, fileList) {
      let str = "";
      fileList.forEach(item => {
        str += item.response.data.url + ",";
      });
      this.form.accountStatement = str.slice(0, -1);
    },
    handlePreview(response, file, fileList) {
      let str = "";
      if (response.code == 200) {
        fileList.forEach(item => {
          str += item.response.data + ",";
        });
        this.form.accountStatement = str.slice(0, -1);
      }
    },
    //上传文件
    handleRemove1(file, fileList) {
      let str = "";
      fileList.forEach(item => {
        str += item.response.data.url + ",";
      });
      this.form.invoiceAnnex = str.slice(0, -1);
    },
    handlePreview1(response, file, fileList) {
      let str = "";
      if (response.code == 200) {
        fileList.forEach(item => {
          str += item.response.data + ",";
        });
        this.form.invoiceAnnex = str.slice(0, -1);
      }
    },
    handleRemove2(file, fileList) {
      let str = "";
      fileList.forEach(item => {
        str += item.response.data.url + ",";
      });
      this.zfForm.file = str.slice(0, -1);
    },
    handlePreview2(response, file, fileList) {
      let str = "";
      if (response.code == 200) {
        fileList.forEach(item => {
          str += item.response.data + ",";
        });
        this.zfForm.file = str.slice(0, -1);
      }
    },
    zhChange(i) {
      let data = { type: i - 1 };
      getListByPay(data).then(res => {
        if (res.code == 200) {
          this.option33 = res.data;
        } else {
          this.option33 = [];
        }
      });
    },
    addFormUpdateItem() {
      this.$set(this.netArrUpdate, this.netArrUpdate.length, {});
    },
    deletenvritem(index) {
      this.netArrUpdate.splice(index, 1);
    },
    onsubmit() {
      let flag = true;
      if (!this.form.type || !this.form.amount) {
        this.$message.error("请完善必填项");
      }
      let data = {};
      if (this.form.type == 1) {
        data = {
          planType: this.form.planType,
          planId: this.form.planId,
          type: this.form.type,
          amount: this.form.amount,
          invoiceAnnex: this.form.invoiceAnnex,
          invoiceType: this.form.invoiceType,
          accountStatement: this.form.accountStatement,
          // account: this.form.account,
          // openBank: this.form.openBank,
          // accountName: this.form.accountName,
          explain: this.form.explain,
          // rmbType: this.form.rmbType,
          sms: this.form.sms
        };
      } else {
        data = {
          type: this.form.type,
          amount: this.form.amount,
          invoiceAnnex: this.form.invoiceAnnex,
          invoiceType: this.form.invoiceType,
          accountStatement: this.form.accountStatement,
          rmbDetail: JSON.stringify(this.netArrUpdate),
          // account: this.form.account,
          // openBank: this.form.openBank,
          // accountName: this.form.accountName,
          explain: this.form.explain,
          // rmbType: this.form.rmbType,
          sms: this.form.sms
        };
      }
      if (flag) {
        rmbAdd(data).then(res => {
          if (res.code == 200) {
            this.getData();
            this.$message({
              type: "success",
              message: res.msg
            });
            this.modal = 0;
            this.zfForm={}
            this.procureDetailForm = {};
            this.fileList=[]
          this.fileList1=[]
          this.fileList3=[]
            this.netArrUpdate = [{}]
              this.form = {
                planType: "1",
                explain: "",
                invoiceType: "1",
                payInfo: {},
                sms: "0"
              }
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
        fmoney(val) {
      if(val){
 //金额转换 分->元 保留2位小数 并每隔3位用逗号分开 1,234.56
        var str = val.toFixed(2) + '';
        var intSum = str.substring(0,str.indexOf(".")).replace( /\B(?=(?:\d{3})+$)/g, ',' );//取到整数部分
        var dot = str.substring(str.length,str.indexOf("."))//取到小数部分搜索
        var ret = intSum + dot;
        return ret;
      }else{
        return '0'
      }    
    },
    handleCommand(d) {
      this.modalState = d.num;
      let data = { id: d.data.id };
      rmbInfo(data).then(res => {
        if (res.code == 200) {
          this.form = res.data;
          if(this.form.type==2){
            this.rmbDetail=res.data.rmbDetail
          }else{
            this.rmbDetail={}
          }
          if (res.data.invoiceAnnex) {
            this.form.invoiceAnnexArr = res.data.invoiceAnnex.split(",");
          } else {
            this.form.invoiceAnnexArr = [];
          }
          if (res.data.accountStatement) {
            this.form.accountStatementArr = res.data.accountStatement.split(
              ","
            );
          } else {
            this.form.accountStatementArr = [];
          }
          if (res.data.type == 1 && res.data.planType == 1) {
            this.procureDetailForm.procureDetail = res.data.procureDetail;
            this.procureDetailForm.freight = res.data.freight;
            this.procureDetailForm.settlement = res.data.settlement;
            this.procureDetailForm.bill = res.data.bill;
            this.procureDetailForm.taxRate = res.data.taxRate;
            this.procureDetailForm.total = res.data.total;
          }

          if (res.data.payInfo) {
            if (res.data.payInfo.file) {
              this.form.payInfo.formFile = res.data.payInfo.file.split(",");
            } else {
              this.form.payInfo.formFile = [];
            }
          } else {
            this.form.payInfo = {};
          }
          this.modal = 1;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    }
  },
  watch: {
    netArrUpdate: {
      deep: true, //深度监听
      handler: function() {
        let num1 = 0;
        this.netArrUpdate.forEach(item => {
          num1 += (isNaN(item.amount) ? 0 : item.amount) - 0;
        });
        this.form.amount = num1;
      }
    }
  },
  mounted() {
    //获取权限
    let arr = JSON.parse(localStorage.getItem("perArr"));
    let routePath = this.$route.path;
    arr.forEach(item => {
      if (item.purl == routePath) {
        this.permissoin.push(item.permission);
      }
    });
    this.getData();
    merchantGetList({ pageSize: 0 }).then(res => {
      if (res.code == 200) {
        this.option3 = res.data.list.filter(item => item.type == 1);
      }
    });
    productGetList({ pageSize: 0 }).then(res => {
      if (res.code == 200) {
        this.option5 = res.data.list.filter(item => item.type == 1);
      }
    });
    dtGetListByType({ type: 1 }).then(res => {
      if (res.code == 200) {
        this.option2 = res.data;
      }
    });
    wxGetOrgList({ pageSize: 0 }).then(res => {
      if (res.code == 200) {
        this.option50 = res.data.list;
      }
    });
        getListByProcure().then(res => {
        if (res.code == 200) {
          this.option4 = res.data;
        } else {
          this.option4 = [];
        }
      });
  }
};
</script>

<style lang="less" >
.br11 {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 18px;
  box-sizing: border-box;
  display: flex;
  .index_main {
    flex: 1;
    background-color: #fff;
    display: flex;
  }
  .index_right {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 28px;
    box-sizing: border-box;
    
    .search {
      margin: 22px 0;
      .el-input {
        width: 208px;
        margin-right: 20px;
      }
    }
    .table {
      flex: 1;
    }
    .block {
      .el-pagination {
        padding: 10px 18px;
      }
    }
    .hide {
      position: absolute;
      top: 48%;
      left: 0px;
      cursor: pointer;
    }
  }
  .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    .inner {
      width: 1126px;
      // max-height: 800px;
      height: 700px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      position: relative;
      box-shadow: 1px 1px 4px #888888;
      .title {
        color: #2b2e36;
        line-height: 40px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #f1f0f0;
        i {
          font-size: 16px;
          cursor: pointer;
        }
      }
      .modal_center {
        flex: 1;
        padding: 0 34px;
        box-sizing: border-box;
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        .el-button--mini,
        .el-button--mini.is-round {
          padding: 6px 12px;
          margin-left: 4px;
        }
        .el-input-number {
          .el-input {
            width: 130px;
          }
        }
        .el-textarea {
          width: 270px;
        }
        .el-textarea__inner {
          width: 270px;
          height: 60px;
        }
        .jstitle {
          margin-bottom: 12px;
          span {
            display: inline-block;
            margin-right: 10px;
          }
          & > span:nth-child(1) {
            width: 100px;
          }
          & > span:nth-child(2) {
            width: 300px;
          }
          & > span:nth-child(3) {
            width: 50px;
          }
          & > span:nth-child(4) {
            width: 68px;
          }
        }
        .el-collapse-item__header {
          width: 350px;
          height: 28px;
          line-height: 28px;
          border: none;
          font-size: 12px;
          color: #000;
        }
        .el-collapse {
          border: none;
          margin-bottom: 10px;
          width: 350px;
          margin-top: -10px;
        }
        .el-collapse-item__content {
          padding-bottom: 0px;
          width: 376px;
          font-size: 12px;
          line-height: 26px;
          color: #999;
          p {
            margin-bottom: 10px;
          }
           .jstitle {
          margin-bottom: 12px;
          span {
            display: inline-block;
            margin-right: 10px;
          }
          & > span:nth-child(1) {
            width: 100px;
          }
          & > span:nth-child(2) {
            width: 300px;
          }
          & > span:nth-child(3) {
            width: 50px;
          }
          & > span:nth-child(4) {
            width: 150px;
          }
        }
        }
        .sel {
          display: flex;
          margin-bottom: 14px;
          line-height: 26px;
          .el-input__inner {
            width: 200px;
          }
          .el-select .el-input {
            width: 200px;
          }
        }
        .blue {
          color: #0090ff !important;
          font-weight: bold;
        }
        .red {
          color: #ff2825 !important;
          font-weight: bold;
        }
        .manyitem {
          color: #000;
          margin-bottom: 14px;

          .title1 {
            display: flex;
            justify-content: space-between;
            padding-right: 20px;
            font-size: 14px;
            p {
              font-weight: bold;
            }
          }
        }
        .el-table th {
          background-color: white;
          padding: 0px;
        }
        .twoitem {
          display: flex;
          justify-content: space-between;
          margin-bottom: 14px;
        }
        .spaa {
          display: inline-block;
          width: 83px;
          margin-left: -9px;
          color: rgba(0, 0, 0);
        }
        .star {
          color: #ff2825;
          font-size: 14px;
          position: relative;
          top: 2px;
        }
        .spa {
          display: inline-block;
          width: 75px;
          color: rgba(0, 0, 0);
        }
        .text_item {
          margin-bottom: 12px;
          & > span:nth-child(1) {
            color: #f56c6c;
            margin-right: 2px;
          }
          & > span:nth-child(2) {
            display: inline-block;
            margin-left: 198px;
            cursor: pointer;
            color: #d62829;
            i {
              font-weight: 600;
              font-size: 14px;
            }
          }
        }
        .net {
          .line {
            border-top: 1px dashed rgb(221, 214, 214);
            width: 612px;
            margin-right: 6px;
          }
          p {
            margin-bottom: 20px;
          }
          .spaa {
            display: inline-block;
            width: 80px;
          }
          .el-select {
            width: 100px;
            margin-right: 10px;
            .el-input {
              width: 100px;
            }
          }
          &>.el-textarea:nth-child(2){
            width: 300px;
            height: 28px;
            margin-right: 10px;
            .el-textarea__inner {
              border: none;
              padding: 0;
               width: 300px;
            height: 28px;
            }           
          }
           &>.el-input:nth-child(3){
            width: 50px;
            margin-right: 10px;
            .el-input__inner {
              border: none;
              padding: 0;
            }
          }
          &>.el-select:nth-child(4){
            width: 150px;
            margin-right: 10px;
            .el-input {
              width: 150px;
            }
            .el-input__inner {
              border: none;
              padding: 0;
            }
          }
          .el-input {
            width: 100px;
            margin-right: 10px;
            .el-input__inner {
              border: none;
              padding: 0;
            }
          }
          .star {
            color: #ff2825;
            font-size: 14px;
            position: relative;
            top: 2px;
          }
        }

        .line {
          border-top: 1px dashed rgb(221, 214, 214);
          width: 412px;
        }
        p {
          margin-bottom: 20px;
        }
        .spaa {
          display: inline-block;
          width: 80px;
        }
        .el-input {
          width: 270px;
        }
        .star {
          color: #ff2825;
          font-size: 14px;
          position: relative;
          top: 2px;
        }
      }
      .bottom {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        & > button:nth-child(1) {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>